<template>
    <div class="index">
        <!--swiper banner -->
        <swiper ref="mySwiper" class="swiper-container" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in bannerList" :key="index">
                <div
                    class="banner other"
                    :style="{ 'background-image': 'url(' + item.image + ')', 'background-position': 'bottom', 'background-size': 'auto 100% ' }"
                    v-if="item.switch === 'other'"
                ></div>

                <!-- 普通banner 一张背景图 一张主图 -->
                <div
                    class="banner normal df-cen-cen"
                    :style="{ 'background-image': 'url(' + item.image + ')', 'background-position': 'bottom', 'background-size': '100% 100% ' }"
                    v-if="item.switch === 'normal'"
                >
                    <img :src="item.subImg" alt="" />
                </div>

                <!-- 多图banner 一张背景图 多张小图 -->
                <div class="banner spec" :style="{ 'background-image': 'url(' + item.image + ')' }" v-if="item.switch === 'spec'">
                    <div class="banner-container df-col-sa-cen">
                        <div class="top" style="width: 100%">
                            <div class="sub-img">
                                <img :src="item.subImg" alt="" />
                            </div>
                        </div>
                        <div class="bottom" style="width: 100%">
                            <div class="sub-text">
                                <img :src="item.subText" alt="" />
                            </div>
                            <img class="sub-btn" @click="goDownload" :src="item.subBtn1" alt="" />
                            <img class="sub-btn" @click="goDownload" :src="item.subBtn2" alt="" />
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <!-- 如果需要滚动条 -->
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- warp-one -->
        <div class="warp warp-one">
            <div class="content df">
                <div class="left">
                    <img :src="ihkData.ihk_server && ihkData.ihk_server.content.list[0].image" alt="" />
                </div>
                <div class="right">
                    <div class="context df-col-cen">
                        <div class="title">{{ ihkData.ihk_server && ihkData.ihk_server.content.list[0].text }}</div>
                        <div class="tip">
                            {{ ihkData.ihk_server && ihkData.ihk_server.content.list[0].content }}
                        </div>
                        <div class="download">
                            <ul class="df" v-if="initData.ihk_code && initData.ihk_code.content">
                                <li>
                                    <img :src="initData.ihk_code.content" alt="" />
                                    <p>iHK App</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-two -->
        <div class="warp warp-two">
            <div class="content df-col-sb-cen">
                <div class="logo">
                    <img :src="ihkData.ihk_intro && ihkData.ihk_intro.content.list[0].image" alt="" />
                </div>
                <div class="title">{{ ihkData.ihk_intro && ihkData.ihk_intro.content.list[0].text }}</div>
                <div class="tips" v-html="ihkData.ihk_intro.content.list[0].content"></div>
                <div class="img-list">
                    <ul class="df-sa-cen">
                        <li v-for="item in 3" :key="item">
                            <img :src="ihkData.ihk_intro && ihkData.ihk_intro.content.list[item].image" alt="" />
                            <div class="context">
                                <div class="title">{{ ihkData.ihk_intro && ihkData.ihk_intro.content.list[item].text }}</div>
                                <div class="remark">{{ ihkData.ihk_intro && ihkData.ihk_intro.content.list[item].content }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn" @click="goDownload" @mouseover="showCode" @mouseout="showCode">
                    立即下載 <em class="el-icon-arrow-right"></em>
                    <div v-show="codePop" class="popup" v-if="initData.ihk_code && initData.ihk_code.content">
                        <img :src="initData.ihk_code.content" alt="" />
                        <p>iHK App</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- warp-three-->
        <div class="warp warp-three">
            <div class="content">
                <div class="title">{{ ihkData.ihk_return && ihkData.ihk_return.content.list[0].text }}</div>
                <div class="tip">{{ ihkData.ihk_return && ihkData.ihk_return.content.list[0].content }}</div>
                <div class="intro">
                    <img src="@/assets/imgs/index/Chart_M@2x.png" alt="" />
                    <ul>
                        <li class="df-col" v-for="(item, index) in ihkData.ihk_return_list.content.list" :key="index">
                            <img :src="item.image" alt="" />
                            <div class="sub_title">{{ item.text }}</div>
                            <div class="sub_tip">{{ item.content }}</div>
                        </li>
                    </ul>
                </div>
                <img class="img" :src="ihkData.ihk_return && ihkData.ihk_return.content.list[0].image" alt="" />
            </div>
        </div>
        <!-- warp-four -->
        <div class="warp warp-four">
            <div class="content">
                <div class="title">{{ initData.merchant && initData.merchant.content }}</div>
                <div class="img-list df-sb-cen">
                    <img v-for="(item, index) in initData.merchant_list.content" :key="index" :src="item" alt="" />
                </div>
            </div>
        </div>
        <!-- warp-five -->
        <div class="warp warp-five">
            <div class="content df-col-cen-cen">
                <img :src="ihkData.ihk_joinus && ihkData.ihk_joinus.content.list[0].image" alt="" />
                <div class="title">{{ ihkData.ihk_joinus && ihkData.ihk_joinus.content.list[0].text }}</div>
                <div class="tip">{{ ihkData.ihk_joinus && ihkData.ihk_joinus.content.list[0].content }}</div>
                <div class="btn" @click="goRouter('about')">加入我們 <em class="el-icon-arrow-right"></em></div>
            </div>
        </div>
    </div>
</template>
<script>
import Head from '@/components/head.vue';
import Foot from '@/components/foot.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        Head,
        Foot,
    },
    computed: {
        ...mapGetters({
            initData: 'app/initData',
            ihkData: 'app/ihkData',
        }),
        bannerList() {
            return this.ihkData.index_banner && this.ihkData.index_banner.content && this.ihkData.index_banner.content.list;
        },
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },
    },
    data() {
        return {
            swiperOptions: {
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: true, // 用户操作轮播图后，比如点击轮播按钮或小圆点，停止自动轮播
                },
                //设置环路
                loop: true,
                // 如果需要滚动条
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // 点击小圆点，也可以滚动轮播图。默认false
                },
            },

            codePop: false,
            mobile: (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768,
        };
    },
    created() {
        window.addEventListener('resize', () => this.isMobile());
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => this.isMobile());
    },
    methods: {
        showCode() {
            if (this.mobile) return;
            this.codePop = !this.codePop;
        },
        goRouter(name) {
            this.$router.push({ name, params: { domId: 'form' } });
        },
        goDownload() {
            this.goRouter('icharge-download');
        },
        isMobile() {
            this.mobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientHclientWidtheight) <= 768;
        },
    },
};
</script>
<style lang="scss" scoped>
.index {
    width: 100%;
    // overflow: hidden;
    // padding-top: pc(143);
}
@media (min-width: 768px) {
    .btn {
        min-width: 140px;
        height: 49px;
        background: #ffaa11;
        border-radius: 4px;
        line-height: 49px;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;

        margin-top: 50px;
        position: relative;
        .popup {
            position: absolute;
            background: rgba(255, 244, 211, 0.39);
            left: 180px;
            top: -25px;
            width: 212px;
            min-height: 200px;
            padding: 16px 25px 0;
            img {
                width: 100%;
            }
            p {
                font-size: 16px;
                color: #333333;
            }
            &::before {
                content: '';
                border: 10px solid rgba(255, 244, 211, 0.39);
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
                border-right-color: rgba(255, 244, 211, 0.39);
                position: absolute;
                left: -20px;
                top: 40px;
            }
        }
    }
    // .banner {
    //     width: 100%;
    //     height: 740px;
    //     background: url('~@/assets/imgs/index/index_bg.png') no-repeat;
    //     background-size: 100% 100%;
    //     img {
    //         width: pc(996);
    //     }
    // }
    .swiper-container {
        padding-top: pc(143);
    }
    .banner {
        // width: 1920px;
        margin: 0 auto;
        height: 740px;
        background-repeat: no-repeat;
        object-fit: cover;
        &-container {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 100px 0;
        }
        &.normal {
            img {
                width: pc(1200);
            }
        }
        &.spec {
            background-position: center;
            background-size: auto 100%;
            .sub-img {
                img {
                    width: pc(736);
                }
            }
            .sub-text {
                margin-bottom: pc(32);
                img {
                    width: pc(292);
                }
            }
            .sub-btn {
                width: pc(288);
                margin-right: pc(28);
                cursor: pointer;
            }
        }
    }
    .warp {
        padding: 60px 0;
    }
    .warp-one {
        background: #f9f9f9;
        .left {
            flex: 4;
            img {
                width: 100%;
            }
        }
        .right {
            flex: 8;
            .context {
                height: 100%;
                text-align: left;
                .title {
                    font-size: 32px;
                    font-weight: bold;
                    //
                }
                .tip {
                    font-size: 16px;
                    margin: 17px 0 32px;
                }
                .download {
                    li {
                        margin-right: 31px;
                        p {
                            font-size: 16px;
                            margin-top: 16px;
                            text-align: center;
                        }
                    }
                    img {
                        width: 131px;
                    }
                }
            }
        }
    }
    .warp-two {
        background: #ffe999;
        .content {
            text-align: center;
            .logo {
                img {
                    width: pc(140);
                }
            }
            .title {
                font-size: 32px;
                font-weight: bold;

                margin: 20px 0;
            }
            .tips {
                width: 65%;
                font-size: 16px;

                p:nth-child(2) {
                    margin: 16px 0;
                }
            }
            .img-list {
                margin-top: 60px;
                ul {
                    align-items: flex-start;
                }
                ul li img {
                    width: 340px;
                    margin-bottom: 32px;
                }
                li {
                    // padding:0 10px;
                    margin: 0 60px;
                }
                .context {
                    .title {
                        font-size: 24px;

                        font-weight: bold;
                    }
                    .remark {
                        text-align: left;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .warp-three {
        text-align: center;
        background: #f9f9f9;
        .title {
            font-size: 32px;

            font-weight: bold;
        }
        .tip {
            font-size: 16px;

            color: #333333;
            margin: 17px 0 60px;
        }
        .img {
            width: 100%;
        }
        .intro {
            display: none;
        }
    }
    .warp-four {
        text-align: center;
        .title {
            font-size: 32px;

            font-weight: bold;
            margin-bottom: 40px;
        }
        .img-list {
            width: 560px;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: flex-start;
            img {
                width: 100px;
                margin: 20px;
            }
        }
    }
    .warp-five {
        background: #96d7d7;
        img {
            width: 394px;
            margin: 50px 0;
        }
        .title {
            font-size: 32px;

            font-weight: bold;
            margin: 10px 0;
        }
        .tip {
            text-align: center;
            width: 40%;
            font-size: 16px;
            margin: 10px 0;
        }
    }
}
@media (max-width: 768px) {
    .index {
        padding-top: pc(128);
    }
    .content {
        width: 90%;
        overflow-x: hidden;
    }
    .btn {
        min-width: 140px;
        height: 49px;
        background: #ffaa11;
        border-radius: 4px;
        line-height: 49px;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;

        margin-top: 50px;
        position: relative;
        .popup {
            position: absolute;
            background: rgba(255, 244, 211, 0.39);
            left: 180px;
            top: -25px;
            width: 212px;
            min-height: 200px;
            padding: 16px 25px 0;
            img {
                width: 100%;
            }
            p {
                font-size: 16px;
                color: #333333;
            }
            &::before {
                content: '';
                border: 10px solid rgba(255, 244, 211, 0.39);
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
                border-right-color: rgba(255, 244, 211, 0.39);
                position: absolute;
                left: -20px;
                top: 40px;
            }
        }
    }
    // .swiper-container{
    //     height: ;
    // }
    .banner {
        width: 100%;
        height: 100vw;
        background-repeat: no-repeat;
        object-fit: cover;
        &-container {
            padding: 66% 20px 20px;
            // height: 30%;
            flex-direction: column-reverse;
            .top {
                text-align: center;
                margin-top: 20px;
            }
            .bottom {
                display: flex;
                justify-content: center;
            }
        }
        &.normal {
            img {
                width: 90%;
            }
        }
        &.spec {
            background-position: top right;
            background-size: auto 60%;
            .sub-img {
                img {
                    width: 58%;
                }
            }
            .sub-text {
                // margin-bottom: pc(32);
                img {
                    width: 90%;
                }
            }
            .sub-btn {
                width: 26%;
                margin-right: pc(28);
                cursor: pointer;
            }
        }
    }
    .warp {
        padding: 60px 0;
    }
    .warp-one {
        background: #f9f9f9;
        .content {
            flex-direction: column;
        }
        .left {
            flex: 4;
            img {
                width: 100%;
            }
        }
        .right {
            flex: 8;
            .context {
                height: 100%;
                text-align: left;
                .title {
                    font-size: 22px;
                    font-weight: bold;
                }
                .tip {
                    font-size: 16px;
                    margin: 17px 0 32px;
                }
                .download {
                    ul {
                        justify-content: center;
                    }
                    li {
                        p {
                            font-size: 16px;
                            margin-top: 16px;
                            text-align: center;
                        }
                    }
                    img {
                        width: 131px;
                    }
                }
            }
        }
    }
    .warp-two {
        background: #ffe999;
        .content {
            text-align: center;
            .logo {
                img {
                    width: pc(140);
                }
            }
            .title {
                font-size: 22px;
                font-weight: bold;
                margin: 20px 0;
            }
            .tips {
                width: 100%;
                font-size: 16px;

                p:nth-child(2) {
                    margin: 16px 0;
                }
            }
            .img-list {
                ul {
                    flex-direction: column;
                }
                ul li img {
                    width: 340px;
                    margin-bottom: 32px;
                }
                li {
                    // padding:0 10px;
                    margin: 60px 0 0;
                }
                .context {
                    .title {
                        font-size: 24px;

                        font-weight: 600;
                    }
                    .remark {
                        text-align: center;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .warp-three {
        text-align: center;
        background: #f9f9f9;
        .title {
            font-size: 22px;

            font-weight: bold;
        }
        .tip {
            font-size: 16px;

            color: #333333;
            margin: 17px 0 60px;
        }
        .img {
            width: 100%;
            display: none;
        }
        .intro {
            & > img {
                width: 100%;
            }
            ul {
                margin-top: 30px;
                li {
                    text-align: left;
                    margin-top: 30px;
                    img {
                        width: 32px;
                    }
                    .sub_title {
                        font-size: 20px;
                        font-weight: 600;
                        margin: 8px 0 10px;
                    }
                    .sub_tip {
                        font-size: 16px;
                        font-weight: 400;
                    }
                    &:nth-child(1) {
                        .sub_title {
                            color: #44bcb9;
                        }
                    }
                    &:nth-child(2) {
                        .sub_title {
                            color: #ffaa11;
                        }
                    }
                    &:nth-child(3) {
                        .sub_title {
                            color: #4eaaff;
                        }
                    }
                    &:nth-child(4) {
                        .sub_title {
                            color: #ef4866;
                        }
                    }
                    &:nth-child(5) {
                        .sub_title {
                            color: #a4af44;
                        }
                    }
                }
            }
        }
    }
    .warp-four {
        text-align: center;
        .title {
            font-size: 22px;

            font-weight: bold;
            margin-bottom: 40px;
        }
        .img-list {
            width: 80%;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: flex-start;
            img {
                width: 40%;
                margin: 0 5% 60px;
            }
        }
    }
    .warp-five {
        background: #96d7d7;
        img {
            width: 90%;
            margin: 0 0 30px;
        }
        .title {
            font-size: 22px;

            font-weight: bold;
            margin: 10px 0;
        }
        .tip {
            text-align: center;
            width: 100%;
            margin: 0 auto;
            font-size: 16px;
        }
    }
}
</style>
